import NavService from './NavService'
import BookDTO, {IBook} from '../DTO/BookDTO'
import DoublePageDTO from '../DTO/DoublePageDTO'
import config from '../config.json'

export type ElementType = {
  id: number,
  name: string,
  propKeyList: string[]
}

export class MainService {
  apiPrefix = config.apiPrefix
  session: {admin: boolean, init: false} = {
    init: false,
    admin: false
  }
  elementTypes: ElementType[] = []
  books: BookDTO[] = []
  reloadFunction: any
  floatIconData = new Map()
  currentDoublePageId: number = -1
  currentDoublePage: DoublePageDTO = new DoublePageDTO({id: -1, sortNumber: -1, pages: []})
  currentDoublePageRef: any
  public nav = new NavService()
  constructor() {
    console.log('MainService start')
    // this.getTest()
    // Get elementTypes:
    fetch(this.apiPrefix + 'elementType/getAll').then((response) => {
      response.json().then((elementTypes: ElementType[]) => this.elementTypes = elementTypes)
    })
  }
  public getElementTypes(): ElementType[]{
    return this.elementTypes
  }

  public setCurrentDoublePage(dP: DoublePageDTO) {
    this.currentDoublePage = dP
    this.currentDoublePageId = dP.id || -1

  }
  /*
  async getTest() {
    let response = await fetch('http://127.0.0.1:3001/api/test')
    let responseJson = await response.json()
  }
  */
  async getBookList() {
    let response = await fetch(this.apiPrefix + 'books')
    let responseJson = await response.json()
    return responseJson.map((b: IBook) => new BookDTO(b))
  }

  async getBook(id?: number) {
    if (!id) {
      id = this.nav.getVal('book') as number
    }
    const book = this.books.find((b) => b.id === id)
    if (book) {
      return book
    }
    let response = await fetch(this.apiPrefix + 'book/' + id)
    let responseJson = await response.json()
    const newBook = new BookDTO(responseJson)
    this.books.push(newBook)
    return newBook
  }

  public readBook(id?: number) {
    if (!id) {
      id = this.getCurrentBookId()
    }
    return this.books.find((b) => b.id === id)
  }

  public getCurrentBookId(): number {
    return this.nav.getVal('book') as number || -1
  }

  public reload() {
    if (this.reloadFunction) {
      this.books = []
      this.reloadFunction()
    }
  }
  public setReloadFunction(rlf: any) {
    this.reloadFunction = rlf
  }

  public getSessionData(key1?: string, key2?: string) {
    const bookId = this.nav.getVal('book') as number
    const book = this.books.find((b) => b.id === bookId)
    if (book) {
      if (key1 && key2) {
        return book.sessionData.find((sD) => sD.key1 === key1 && sD.key2 === key2)
      }
      return book.sessionData
    }
    return []
  }

  public async setSessionData(key1: string, key2: string, value1: string, value2: string) {
    const bookId = this.nav.getVal('book')
    const book = this.books.find((b) => {
      return b.id === parseInt(`${bookId}`, 10)
    })
    if (book) {
      await book.setSessionData(key1, key2, value1, value2)
    }
  }

  isAdmin(): boolean {
    if (!this.session.init) {
      if (document.cookie.search('isAdmin=true') > -1) {
        this.session.admin = true
      }
    }
    return this.session.admin
  }
  becomeAdmin(): void {
    document.cookie = 'isAdmin=true'
    this.session.admin = true
  }
  quitAdmin(): void {
    if (document.cookie.search('isAdmin=true') > -1) {
      document.cookie = 'isAdmin=false'
    }
    this.session.admin = false
  }

  public registerFloatIcon(name: string, floatIcon: any) {
    this.floatIconData.set(name, floatIcon)
  }

  public isCurrentDoublePage(doublePageId: number) {
    return doublePageId === this.currentDoublePageId
  }

  public setFloatIconCoordinates(name: string, doublePage: number, x: number, y: number) {
    // quit if request is not for the current page:
    if (!this.isCurrentDoublePage(doublePage)) { return }
    const fI = this.floatIconData.get(name)
    // quit if floatIcon not available:
    if (!fI) { return }
    // Get Scroll:
    if (this.currentDoublePageRef) {
      y -= this.currentDoublePageRef.current.scrollTop
    }
    // comand to new target:
    fI.setNewTarget(fI, x, y)
  }
}
