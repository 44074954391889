import React from 'react'
import './CakeA.scss'

type DataProp = {
  name?: string,
  key: string,
  amount: number
}

type RenderData = {
  key: string,
  deg: number
}

type CakeAProps = {
  data: DataProp[]
}

type CakeAState = {
  renderData: RenderData[],
  showAmount: number
}

class CakeA extends React.Component<CakeAProps, CakeAState> {
  state: CakeAState
  delay: any
  constructor(props: CakeAProps) {
    super(props)
    this.state = {
      renderData: this.generateRenderData(props.data),
      showAmount: 0
    }
  }

  componentDidMount() {
    this.animate()
  }

  animate() {
    clearTimeout(this.delay)
    this.delay = setTimeout(() => {
      let animate = setInterval(() => {
        this.setState({showAmount: this.state.showAmount + 5})
        if (this.state.showAmount > 364) {
          clearInterval(animate)
        }
      }, 10)
    }, 700)
  }

  componentWillReceiveProps(props: CakeAProps) {
    this.setState({
      renderData: this.generateRenderData(props.data),
      showAmount: 0
    })
    this.animate()
  }

  generateRenderData(data: DataProp[]): RenderData[] {
    // Calc Full Amount
    let full = 0
    let i = 0
    let result: RenderData[] = []
    data.forEach((d) => full += d.amount)
    if (full === 0) {
      return []
    }
    // Set elements for all data:
    data.forEach((d) => {
      let j = 0
      const segments = d.amount / full * 364
      while(j < segments && i < 364) {
        result.push({
          key: d.key,
          deg: i
        })
        i += 1
        j += 1
      }
    })
    return result
  }

  render() {
    const showAmount = this.state.showAmount
    let i = 0
    return <div className='w3-row cakeA'>
      <div className='cakePlateHolder w3-col s12 m6 l6'>
        <div className='cakeAPlate'>
          {
            this.state.renderData.map((a) => {
              if (i > showAmount) {
                return
              }
              i += 1
              const style = {
                transform: `rotate(${a.deg}deg)`
              }
              return <div key={`cakePieceKey${a.deg}`} className={`cakeAPiece cakeAPieceKey-${a.key}`} style={style}></div>
            })
          }
        </div>
      </div>
      <div className='cakeALegend w3-col s12 m6 l6'>
        {
          this.props.data.map((d) => {
            return <div key={d.key} className='cakeALegendEntry'>
              <div className={`marker cakeAPieceKey-${d.key}`}></div>
              <div className='description'>
                {d.name}
              </div>
            </div>
          })
        }
      </div>
    </div>
  }
}

export default CakeA
