import config from '../config.json'
import DoublePageDTO, {IDoublePage} from './DoublePageDTO'
import PageElementDTO from './PageElementDTO'

export default class BookDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public name: string
  public doublePages: DoublePageDTO[] = []
  public groupId: number = 1
  public sessionData: {key1: string, key2: string, value1: string, value2: string}[] = []
  public sessionHash: string = ''

  constructor(o: IBook) {
    this.id = o.id
    this.name = o.name
    if (o.doublePages) {
      console.log('BookDTO -> doublePages: ', o.doublePages)
      this.doublePages = o.doublePages.sort((a, b) => {
        return a.sortNumber < b.sortNumber ? -1 : 1
      }).map((dP: IDoublePage) => new DoublePageDTO(dP, this))
    }
  }
  public async setName(name: string) {
    if (!name) { return }
    this.name = name
    if (this.id > -1) {
      await fetch(this.apiPrefix + 'book/' + this.id + '/rename/' + name)
    } else {
      await fetch(this.apiPrefix + 'book/add/' + name)
    }
  }

  public async addDoublePage(position: number) {
      await fetch(`${this.apiPrefix}book/${this.id}/addDoublePage/${position * 10 - 1}`)
  }

  public async remove() {
    await fetch(`${this.apiPrefix}book/${this.id}/remove`)
  }

  public async getSession(sessionHash: string) {
    // Do we have session?
    if (!sessionHash) {
      const response = await fetch(`${this.apiPrefix}session/add/book/${this.id}/group/${this.groupId}`)
      let responseJson = await response.json()
      sessionHash = responseJson.hash
      console.log('sessionHash', responseJson, sessionHash)
    }
    const sResponse = await fetch(`${this.apiPrefix}session/${sessionHash}`)
    const sResponseJson = await sResponse.json()
    this.sessionData = sResponseJson.data
    this.sessionHash = sessionHash
    return sessionHash
  }

  public async setSessionData(key1: string, key2: string, value1: string, value2: string) {
    await fetch(`${this.apiPrefix}session/${this.sessionHash}/add/${key1}/${key2}/${value1}/${value2}`)
    console.log('sessionData', this.sessionData)
    const d = this.sessionData.find((sD) => sD.key1 === key1 && sD.key2 === key2)
    if (d) {
      d.value1 = value1
      d.value2 = value2
    } else {
      this.sessionData.push({
        key1: key1,
        key2: key2,
        value1: value1,
        value2: value2
      })
    }
  }

  public getAllPageElements(): PageElementDTO[] {
    let out: PageElementDTO[] = []
    this.doublePages.forEach((dP) => {
      out = out.concat(dP.getAllPageElements())
    })
    return out
  }
}

export interface IBook {
  id: number
  name: string
  bookProps?: any[]
  doublePages?: IDoublePage[]
}
