import React from 'react'
// import ReactDOM from 'react-dom'
import { MainService } from './services/MainService'
// import { NavState } from './services/NavService'
import Booklist from './components/Booklist/Booklist'
import Book from './components/Book/Book'
import CakeA from './components/CakeA/CakeA'

type MainProps = {

}

type MainState = {
  view: string
}

class Main extends React.Component<MainProps, MainState> {
  mainService
  constructor(props: any) {
    super(props)
    this.mainService = new MainService()
    this.mainService.nav.getRoute()
    this.state = {
      view: this.mainService.nav.getVal('view') as string
    }
  }
  nav() {
    this.setState({
      view: this.mainService.nav.getVal('view') as string
    })
  }

  selectBook(id: number) {
    this.mainService.nav.setRoute([
      ['view', 'book'],
      ['book', id],
      ['page', 1]
    ])
    this.nav()
  }
  goHome() {
    this.mainService.nav.setRoute([
      ['view', 'home'],
    ])
    this.nav()
  }

  render() {
    const view = this.state.view
    console.log('VIEW', view)
    // Fight admin bug:
    /*
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000)
    */
    switch(view) {
      case 'login':
        {
          this.mainService.becomeAdmin()
        }
        return <>Logged in!</>
      case 'logout':
        {
          this.mainService.quitAdmin()
        }
        return <>Logged out!</>
      case 'book':
        return <>
          <Book mainservice={this.mainService} selectFkt={(command: string) => {
            if (command === 'goHome') {
              this.goHome()
            }
          }}/>
        </>
      case 'cakeA':
        return <CakeA data={[{key: 'A', amount: 50}, {key: 'B', amount: 50}]}/>
      default:
        return <>
            <Booklist mainservice={this.mainService} selectFkt={(id: number) => this.selectBook(id)}/>
          </>
    }

  }
}

export default Main
