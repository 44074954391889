import React from 'react'
import BookDTO from '../../DTO/BookDTO'
import DoublePageDTO from '../../DTO/DoublePageDTO'
import Spinner from '../Spinner/Spinner'
import FloatIcon from '../FloatIcon/FloatIcon'
import DoublePage from '../DoublePage/DoublePage'
import {ChevronLeft} from 'react-feather'
import './Book.scss'

type BookProps = {
  mainservice: any,
  selectFkt: any
}

type BookState = {
  loading: boolean,
  book?: BookDTO,
  isAdmin: boolean,
  currentDoublePage: number,
  adminEndlessMode: boolean,
  enableNextPage: boolean,
  doublePageSection: DoublePageSection[]
}

type DoublePageSection = {
  doublePage: DoublePageDTO,
  index: number,
  sliderClass: string,
  render: boolean
}

class Book extends React.Component<BookProps, BookState> {
  mainService: any
  selectFkt: any
  state: BookState
  bookContentRef: any
  activeDoublePageRef: number = -1
  doublePageRefIndex = new Map()
  nextPageBlockInterval: any
  constructor(props: BookProps) {
    super(props)
    this.mainService = props.mainservice
    this.selectFkt = props.selectFkt
    this.bookContentRef = React.createRef()
    this.state = {
      loading: true,
      isAdmin: this.mainService.isAdmin(),
      currentDoublePage: this.mainService.nav.getVal('page'),
      adminEndlessMode: false,
      enableNextPage: true,
      doublePageSection: []
    }
  }


  setDoublePageSection(options: {
    book?: BookDTO,
    currentDoublePageIndex?: number
  }) {
    const book = options.book || this.state.book
    const currentDoublePageIndex = options.currentDoublePageIndex || this.state.currentDoublePage || 1
    if (!book) { return }

    let enableNextPage: boolean = true
    let currentDoublePage: DoublePageDTO = new DoublePageDTO({
      id: -1, sortNumber: -1, pages: []
    })
    const doublePageSection = book.doublePages.map((dP, realIndex: number) => {
      const index = realIndex + 1
      let render = false
      let sliderClass = (!this.state.adminEndlessMode) ? 'doublePageSlider ' : 'doublePageEndless not'
      if (index === currentDoublePageIndex + 1) {
        sliderClass += 'toTheRight'
        render = true
      }
      if (index === currentDoublePageIndex - 1) {
        sliderClass += 'toTheLeft'
        render = true
      }
      if (index === currentDoublePageIndex) {
        enableNextPage = dP.nextPageEnabled
        currentDoublePage = dP
        render = true
      }
      // Add Style:

      sliderClass += ` layout-${dP.getLayout()}`
      return {
        doublePage: dP,
        index: index,
        sliderClass: sliderClass,
        render: render
      }
    })
    this.setState({
      enableNextPage: enableNextPage,
      doublePageSection: doublePageSection,
      loading: false,
      book: book,
      currentDoublePage: currentDoublePageIndex
    })
    if (currentDoublePage) {
      this.mainService.setCurrentDoublePage(currentDoublePage)
      // currentDoublePage.acceptSetEnableNextPage(this.setEnableNextPage, this)
    }
  }

  getContent() {
    const bookId = this.mainService.nav.getVal('book')
    this.mainService.getBook(bookId).then((r: BookDTO) => {
      r.getSession(this.mainService.nav.getVal('session') as string).then((hash: string) => {
        this.mainService.nav.setVal('session', hash)
        this.setDoublePageSection({book: r})
      })
    })
  }

  componentDidMount() {
    this.getContent()
    this.nextPageBlockInterval = setInterval(() => {
      if (!this.mainService.currentDoublePage) { return }
      const nextPageEnabled = this.state.enableNextPage
      const newState = this.mainService.currentDoublePage.nextPageEnabled
      if (nextPageEnabled !== newState) {
        this.setState({enableNextPage: newState})
      }
    }, 1000)
  }
  componentDidUnount() {
  }
  componentWillUnount() {
    clearInterval(this.nextPageBlockInterval)
  }
  componentDidUpdate() {

  }

  addDoublePage(position: number) {
    if (!this.state.book) { return }
    this.setState({loading: true})
    this.state.book.addDoublePage(position).then(() => this.getContent())
  }

  renderDoublePages() {
    const book = this.state.book
    if (!book) { return }
    if (this.state.isAdmin &&
      (!book.doublePages || book.doublePages.length === 0)
    ) {
      return <div className="addBlockRoot"><div className="addBlock" key={'AddPage-1'} onClick={() => this.addDoublePage(1)}>Erste Doppel-Seite hinzufügen</div></div>
    }
    return this.state.doublePageSection.map((dP) => {

      if (!dP.render) {
        return <div style={{display: 'none'}} ref={this.manageDPRef(dP.doublePage.id, this.state.currentDoublePage === dP.index)} key={'doublePage' + dP.doublePage.id}></div>
      }
      return <div ref={this.manageDPRef(dP.doublePage.id, this.state.currentDoublePage === dP.index)} className={dP.sliderClass} key={'doublePage' + dP.doublePage.id}>
        <DoublePage doublePage={dP.doublePage} mainservice={this.mainService} addBookDoublePage={(position: number) => this.addDoublePage(position)}/>
      </div>
    })
  }

  manageDPRef(id: number, current: boolean) {
    const ref = this.doublePageRefIndex.get(id)
    if (ref) {
      if (current) {
        this.mainService.currentDoublePageRef = ref
      }
      return ref
    }
    const newRef = React.createRef()
    this.doublePageRefIndex.set(id, newRef)
    if (current) {
      this.mainService.currentDoublePageRef = newRef
    }
    return newRef
  }

  changeCurrentPage(newPage: number) {
    if (!this.state.book) { return }
    if (newPage < 1) {
      newPage = 1
    }
    if (newPage > this.state.book.doublePages.length - 1) {
      newPage = this.state.book.doublePages.length
    }
    this.mainService.nav.setPage(newPage)
    this.setDoublePageSection({currentDoublePageIndex: newPage})
    /*
    this.setState({
      currentDoublePage: newPage
    })
    */
  }

  renderToolBar() {
    if (!this.state.isAdmin) { return <></> }

    return <div className="bookAdminToolBar adminToolBar w3-container">
      <button onClick={() => {
        this.props.selectFkt('goHome')
      }}>Home</button>
      <button className={this.state.adminEndlessMode ? 'w3-green' : 'w3-blue-gray'} onClick={() => this.setState({adminEndlessMode: !this.state.adminEndlessMode})}>
        Alle DoublePages zeigen
      </button>
    </div>
  }

  render() {
    const book = this.state.book
    if (!book) { return <></>}

    const doublePages = this.renderDoublePages()
    let bookContentClassName = 'book-content'
    if (this.state.isAdmin) {
      bookContentClassName += ' book-content-scroll'
    }
    // Fight scroll Bug in Admin:
    setTimeout(() => {
      this.bookContentRef.current.scrollTo(0, 0)
    }, 500)
    const nextPageButtonClass = `centerButton ${((this.state.enableNextPage) ? 'active' : 'disabled')}`
    return <>
      <FloatIcon mainservice={this.mainService} iconName={'A'} />
      <FloatIcon mainservice={this.mainService} iconName={'B'} />
      <FloatIcon mainservice={this.mainService} iconName={'C'} />
      <FloatIcon mainservice={this.mainService} iconName={'D'} />
      {
        this.state.loading && <Spinner key={'bookSpinner' + book.id} />
      }
      {this.renderToolBar()}
      <div ref={this.bookContentRef} className={bookContentClassName}>
        {doublePages}
      </div>
      <div className='footer w3-container'>
        <ChevronLeft className="button floatLeft" size='42px' onClick={() => this.changeCurrentPage(this.state.currentDoublePage - 1)} />

        <button className={nextPageButtonClass} onClick={() => {
          if (this.state.enableNextPage) {
            this.changeCurrentPage(this.state.currentDoublePage + 1)
          }
        }}>weiter</button>
      </div>
    </>
  }
}

export default Book
