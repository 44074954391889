import config from '../config.json'
import PageDTO, {IPage} from './PageDTO'
import PageElementDTO from './PageElementDTO'
import DoublePagePropDTO, {IDoublePageProp} from './DoublePagePropDTO'

type ElementBlockNextPage = {id: number, block: boolean}

export default class DoublePageDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public sortNumber: number
  public pages: PageDTO[]
  public parentItem: any
  public nextPageEnabled: boolean = true
  public props: DoublePagePropDTO[] = []
  private elementBlockNextPage: ElementBlockNextPage[] = []

  constructor(o: IDoublePage, parentItem?: any) {
    this.id = o.id
    if (parentItem) {
      this.parentItem = parentItem
    }
    this.sortNumber = o.sortNumber * 0.1
    this.pages = o.pages.sort((a, b) => {
      return a.sortNumber < b.sortNumber ? -1 : 1
    }).map((p) => new PageDTO(p, this))
    this.props = (o.props || []).map((p) => new DoublePagePropDTO(p))
  }
  // Next Page is only available if all blocker do unblock:
  public blockNextPage(elementId: number, block: boolean) {
    let item = this.elementBlockNextPage.find((item: ElementBlockNextPage) => item.id === elementId)
    if (!item) {
      this.elementBlockNextPage.push({id: elementId, block: block})
    } else {
      item.block = block
    }
    this.nextPageEnabled = !this.elementBlockNextPage.some((item: ElementBlockNextPage) => item.block)
  }


  public async setSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    await fetch(`${this.apiPrefix}DoublePage/${this.id}/setSortNumber/${(sortNumber * 10) - 1}`)
    // this.sortNumber = sortNumber - 0.1
    // TODO - talk to API
  }

  public async remove() {
    await fetch(`${this.apiPrefix}DoublePage/${this.id}/remove`)
  }

  public async addPage(position: number) {
    await fetch(`${this.apiPrefix}DoublePage/${this.id}/addPage/${position * 10 - 1}`)
  }

  public getAllPageElements(): PageElementDTO[] {
    let out: PageElementDTO[] = []
    this.pages.forEach((p) => {
      out = out.concat(p.getAllPageElements())
    })
    return out
  }

  public async addProp(prop: DoublePagePropDTO) {
    const e = prop.get()
    this.props.push(prop)
    await fetch(`${this.apiPrefix}DoublePage/${this.id}/addProp/${e.key1}/${e.key2}/${e.value1}/${e.value2}`)
  }

  public async setLayout(name: string) {
    const layout = this.props.find((p) => p.key1 === 'layout')
    if (!layout) {
      this.addProp(new DoublePagePropDTO({
        id: -1, key1: 'layout', key2: '-', value1: name, value2: '-'
      }))
      return
    }
    layout.setValue1(name)
  }

  public getLayout() {
    const layout = this.props.find((p) => p.key1 === 'layout')
    if (layout) {
      return layout.value1
    }
  }

  public async clone(targetBookId: number) {
    await fetch(`${this.apiPrefix}DoublePage/${this.id}/clone/${targetBookId}/${this.sortNumber * 10 + 1}`)
    // /api/DoublePage/{id}/clone/{targetBookId}/{targetSortNumber}
  }

}

export interface IDoublePage {
  id: number
  sortNumber: number
  pages: IPage[]
  props?: IDoublePageProp[]
}
