import React from 'react'
import PageDTO from '../../DTO/PageDTO'
// import Spinner from '../Spinner/Spinner'
import PageElement from '../PageElement/PageElement'
import './Page.scss'
import {ElementType} from '../../services/MainService'
import {Trash} from 'react-feather'

type PageProps = {
  mainservice: any,
  selectFkt: any,
  page: PageDTO
}

type PageState = {
  loading: boolean,
  page: PageDTO,
  isAdmin: boolean,
  editElementIndex: number,
  sortNumber: number
}

class Page extends React.Component<PageProps, PageState> {
  mainService: any
  selectFkt: any
  state: PageState
  constructor(props: PageProps) {
    super(props)
    this.mainService = props.mainservice
    this.selectFkt = props.selectFkt

    this.state = {
      loading: true,
      isAdmin: this.mainService.isAdmin(),
      page: props.page,
      editElementIndex: -2,
      sortNumber: props.page.sortNumber
    }
  }
  componentDidMount() {
  }
  componentDidUnount() {
  }
  getContent() {
    // TODO - please use nicer solution:
    window.location.reload()
  }
  addPageElement(elementTypeId: number, position: number) {
    console.log('addPageElement', position)
    if (!this.state.page) { return }
    this.setState({loading: true})
    this.state.page.addPageElement(elementTypeId, position).then(() => this.getContent())
  }
  renderElementTypeDropDown(index: number) {
    const elementTypes = this.mainService.getElementTypes()
    console.log('elementTypes', elementTypes)
    let elementTypeId = (elementTypes[0]) ? elementTypes[0].id : 1
    const elementTypeOptions = elementTypes.map(
      (eT: ElementType) => {
        return <option
          key={'elementTypeDropDownOption' + eT.id}
          onClick={() => elementTypeId = eT.id}
        >
          {eT.name}
        </option>
      }
    )
    return <div className="addBlock">
      <select>
        {elementTypeOptions}
      </select>
      <button onClick={() => {
        this.addPageElement(elementTypeId, index)
        this.setState({editElementIndex: -2})
      }}>
        okay
      </button>
      <button onClick={() => this.setState({editElementIndex: -2})}>
        Abbrechen
      </button>
    </div>
  }
  renderAddButton(index: number) {
    return <div className="addBlockRoot">
      {
        this.state.isAdmin &&
        index !== this.state.editElementIndex &&
        <div className="addBlock" onClick={
          () => this.setState({editElementIndex: index})
        }>
          PageElement
        </div>
      }
      {
        this.state.isAdmin && index === this.state.editElementIndex &&
        this.renderElementTypeDropDown(index)
      }
    </div>
  }
  renderPageElements() {
    const page = this.state.page
    if (!page) { return }
    const pageElementsOut = page.pageElements.map((pE, index: number) => {
      return <div key={'pageElement' + pE.id}>
        <PageElement key={'pageElement' + pE.id} pageElement={pE} mainservice={this.mainService} selectFkt={(id: number) => console.log('selected id: ', id)}/>
        {this.renderAddButton(index + 2)}
      </div>
    })
    return <>
      {this.renderAddButton(1)}
      {pageElementsOut}
    </>
  }
  renderToolBar() {
    return <div className="adminToolBar">
    <span className="w3-tag">Page:</span>
    <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
    <button onClick={() => this.state.page.setSortNumber(this.state.sortNumber).then(() => this.getContent())}>ok</button>
      <Trash className="tool" size="16px" onClick={() => this.state.page.remove().then(() => this.getContent())} />
    </div>
  }
  render() {
    const className = (this.state.isAdmin) ? 'pageAdmin' : 'page'
    const pageElements = this.renderPageElements()
    return <div className={className}>
      {this.state.isAdmin && this.renderToolBar()}
      {pageElements}
    </div>
  }
}

export default Page
