import React from 'react'
import PageElementDTO from '../../DTO/PageElementDTO'
import ElementPropDTO from '../../DTO/ElementPropDTO'
import Evaluation1 from './Evaluation1/Evaluation1'
import Text from './Text/Text'
import IconMagnet from './IconMagnet/IconMagnet'
import MultipleChoice from './MultipleChoice/MultipleChoice'
// import Spinner from '../Spinner/Spinner'
import { Trash, Settings } from 'react-feather'
import './PageElement.scss'


type PageElementProps = {
  mainservice: any,
  selectFkt: any,
  pageElement: PageElementDTO,
}

type PageElementState = {
  loading: boolean,
  pageElement: PageElementDTO,
  isAdmin: boolean,
  adminEdit: boolean,
  editValue1Status: string,
  editValue2Status: string,
  sortNumber: number,
  propKey: string,
  emptyProp: ElementPropDTO
}

class PageElement extends React.Component<PageElementProps, PageElementState> {
  mainService: any
  selectFkt: any
  state: PageElementState
  adminToolIconSize: string = '16px'
  constructor(props: PageElementProps) {
    super(props)
    this.mainService = props.mainservice
    this.selectFkt = props.selectFkt

    this.state = {
      loading: true,
      isAdmin: this.mainService.isAdmin(),
      pageElement: props.pageElement,
      adminEdit: false,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
      sortNumber: props.pageElement.sortNumber,
      propKey: '',
      emptyProp: new ElementPropDTO({id: -1, key1: '', key2: '', value1: '', value2: ''})
    }
  }
  componentDidMount() {
  }
  componentDidUnount() {
  }
  getContent() {
    // TODO - please use nicer solution:
    window.location.reload()
  }
  renderToolBar() {
    const kinds = [
      { key: 'p', name: 'Paragraph' },
      { key: 'bold-paragraph', name: 'Fetter Paragraph' },
      { key: 'poster', name: 'Sinnspruch' },
      { key: 'h1', name: 'Haupt-Überschrift' },
      { key: 'h2', name: 'Unter-Überschrift' },
    ]
    const kindOptions = kinds.map((k) => <option key={k.key} value={k.key}>{k.name}</option>)
    return <div className="adminToolBar">
      <span className="w3-tag">Element:</span>
      <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
      <button onClick={() => this.state.pageElement.setSortNumber(this.state.sortNumber).then(() => this.getContent())}>ok</button>
      <span className='w3-margin-left w3-margin-right'>{this.state.pageElement.elementType.name}</span>
      <Settings className="tool" size={this.adminToolIconSize} onClick={() => this.setState({adminEdit: true})}/>
      <Trash className="tool" size={this.adminToolIconSize} onClick={() => this.state.pageElement.remove().then(() => this.getContent())} />
      {
        this.state.pageElement.elementType.name === 'text' &&
        <select value={this.state.pageElement.value2 || ''} onChange={(event) => this.state.pageElement.setValue2(event.target.value).
          then(() => {
            this.setState({adminEdit: false})
          })}>
          {kindOptions}
        </select>
      }
    </div>
  }

  render() {
    const className = (this.state.isAdmin) ? 'pageElementAdmin' : 'pageElement'
    return <div className={`${className} w3-container`}>
      {this.state.isAdmin && this.renderToolBar()}
      {this.renderElement()}
    </div>
  }

  renderElement() {
    switch(this.state.pageElement.elementType.name) {
      case 'multiplechoice':
        return <MultipleChoice mainservice={this.mainService} pageElement={this.state.pageElement} />
      case 'text':
        return <Text mainservice={this.mainService} pageElement={this.state.pageElement} />
      case 'iconmagnet':
        return <IconMagnet mainservice={this.mainService} pageElement={this.state.pageElement} />
      case 'evaluation1':
        return <Evaluation1 mainservice={this.mainService} pageElement={this.state.pageElement} />

    }
  }
}

export default PageElement
