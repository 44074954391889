import React from 'react'
import BookDTO from '../../DTO/BookDTO'
import Spinner from '../Spinner/Spinner'

type BookListProps = {
  mainservice: any,
  selectFkt: any
}
type BookListState = {
  loading: boolean
  booklist: BookDTO[]
  editBookId: number
  editBookNameStatus: string
}

class Booklist extends React.Component<BookListProps, BookListState> {
  mainservice: any
  selectFkt: any
  state: BookListState
  constructor(props: BookListProps) {
    super(props)
    this.state = {
      loading: true,
      booklist: [],
      editBookId: -1,
      editBookNameStatus: ''
    }
    this.mainservice = props.mainservice
    this.selectFkt = props.selectFkt

  }
  getContent() {
    this.mainservice.getBookList().then((r: BookDTO[]) => {
      this.setState({loading: false, booklist: r})
    })
  }
  componentDidMount() {
    this.getContent()
  }
  componentDidUnount() {
  }

  startBookEdit(book: BookDTO) {
    this.setState({editBookId: book.id, editBookNameStatus: book.name})
  }

  trackRenameChange(event: any) {
    const scope = /(.*)[\r\n]/
    if (event.target.value.search(scope) > -1) {
      const newName = event.target.value.match(scope)[1]
      this.setState({editBookNameStatus: newName})
      return
    }
    this.setState({editBookNameStatus: event.target.value})
  }

  handleRenameBookKeyUp(book: BookDTO, event: any) {
    if (event.key === 'Enter') {
      this.doRenameBook(book)
    }
  }

  doRenameBook(book: BookDTO) {
    book.setName(this.state.editBookNameStatus).then(() => this.getContent())
    this.setState({editBookNameStatus: '', editBookId: -1})
  }

  removeBook(book: BookDTO) {
    book.remove().then(() => this.getContent())
    this.setState({editBookNameStatus: '', editBookId: -1})
  }

  renderBook(book: BookDTO) {
    const isAdmin = this.mainservice.isAdmin()
    console.log('isAdmin', isAdmin)
    return <li key={'book' + book.id} className="w3-bar hoverHighLight">
      <div className="avatar w3-bar-item w3-circle bg-color-1">📖</div>
      <div className="w3-bar-item">
        {
          this.state.editBookId !== book.id &&
          <span className="w3-large pointer" onClick={() => this.selectFkt(book.id)}>
            {book.name}
          </span>
        }
        {
          isAdmin && this.state.editBookId === book.id &&
          <>
            <input autoFocus type="text" value={this.state.editBookNameStatus} onChange={(event) => this.trackRenameChange(event)} onKeyUp={(event) => this.handleRenameBookKeyUp(book, event)}/>
            <button className="w3-btn w3-green" onClick={() => this.doRenameBook(book)}>ok</button>
          </>
        }

      </div>
      {
        isAdmin && this.state.editBookId !== book.id &&
        <div className="w3-bar-item">
          <button className="w3-btn w3-yellow" onClick={() => this.startBookEdit(book)}>editieren</button>
        </div>
      }
      {
        isAdmin &&
        <div className="w3-bar-item">
        <button className="w3-btn w3-red" onClick={() => this.removeBook(book)}>löschen</button>
        </div>
      }
    </li>
  }
  render() {
    const isAdmin = this.mainservice.isAdmin()
    const books = this.state.booklist.map((b: BookDTO) => this.renderBook(b))
    const newBook = new BookDTO({id: -2, name: ''})
    return <>
      <div className="w3-container header">
        <h2>Bücher</h2>
      </div>
      {
        this.state.loading && <Spinner />
      }
      <ul className="w3-ul w3-card-4 w3-margin">
      {
        books
      }
      </ul>

      {
        isAdmin && this.state.editBookId !== -2 &&
        <div className="w3-bar-item">
          <button className="w3-btn w3-green" onClick={() => this.startBookEdit(newBook)}>Hinzufügen</button>
        </div>
      }
      {
        isAdmin && this.state.editBookId === -2 &&
        <>
          <input autoFocus type="text" value={this.state.editBookNameStatus} onChange={(event) => this.trackRenameChange(event)} onKeyUp={(event) => this.handleRenameBookKeyUp(newBook, event)}/>
          <button className="w3-btn w3-green" onClick={() => this.doRenameBook(newBook)}>ok</button>
        </>
      }
    </>
  }
}

export default Booklist
