import React from 'react'
import PageElementDTO from '../../../DTO/PageElementDTO'
import './IconMagnet.scss'

type Props = {
  mainservice: any,
  pageElement: PageElementDTO
}

type State = {
  isAdmin: boolean,
  adminEdit: boolean,
  pageElement: PageElementDTO,
  editValue1Status: string,
  editValue2Status: string,
}

class IconMagnet extends React.Component<Props, State> {
  mainService: any
  state: State
  possiblePositions = ['left', 'center', 'right']
  possibleIcons = ['A', 'B', 'C', 'D', 'ABCD']
  doublePageId: number
  ref1 = React.createRef()
  ref2 = React.createRef()
  ref3 = React.createRef()
  ref4 = React.createRef()
  timerID: any
  constructor(props: Props) {
    super(props)
    this.mainService = props.mainservice
    this.state = {
      isAdmin: this.mainService.isAdmin(),
      adminEdit: false,
      pageElement: props.pageElement,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
    }
    this.doublePageId = this.state.pageElement.getDoublePageID()
  }

  componentDidUpdate() {

  }
  componentDidMount() {
    this.timerID = setInterval(() => this.suck(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  suck() {
    if (!this.ref1 || !this.ref1.current) { return }
    const q = 20
    const x = (this.ref1.current as {offsetLeft: number}).offsetLeft
    const y = (this.ref1.current as {offsetTop: number}).offsetTop
    if (this.state.pageElement.value1 === 'ABCD') {
      this.mainService.setFloatIconCoordinates('A', this.doublePageId, x - q, y + q)
      this.mainService.setFloatIconCoordinates('B', this.doublePageId, x + q, y + q)
      this.mainService.setFloatIconCoordinates('C', this.doublePageId, x - q, y + 3*q)
      this.mainService.setFloatIconCoordinates('D', this.doublePageId, x + q, y + 3*q)

    } else if (this.ref1.current) {
      this.mainService.setFloatIconCoordinates(this.state.pageElement.value1, this.doublePageId, x, y)
    }
  }

  renderMagnet(ref: any) {
    return <div ref={ref}></div>
  }

  render() {
    const active = 'w3-ripple w3-blue'
    const passive = 'w3-white w3-border'
    if (this.state.isAdmin) {
      return <>
        <div>Position:</div>
        <div className="w3-row">
           {
             this.possiblePositions.map((pP, index) => <button key={index} className={'w3-col s4 ' + (this.state.pageElement.value2 === pP ? active : passive)} onClick={() => {
               this.state.pageElement.setValue2(pP)
             }}>
               {pP}
             </button>)
           }
        </div>
        <div>Icon:</div>
        <div className="w3-row">
          {
            this.possibleIcons.map((pI, index) => <button key={index} className={'w3-col s3 ' + (this.state.pageElement.value1 === pI ? active : passive)} onClick={() => {
              this.state.pageElement.setValue1(pI)
            }}>
              {pI}
            </button>)
          }
        </div>
      </>
    }
    let alignClass = 'doCenter'
    if (this.state.pageElement.value2 === 'left') {
      alignClass = 'floatLeft'
    } else if (this.state.pageElement.value2 === 'right') {
      alignClass = 'floatRight'
    }
    return <div className={`magnet ${alignClass} magnet-${this.state.pageElement.value1}`} key={`magnet-${this.state.pageElement.value1}`}>
      {
        this.renderMagnet(this.ref1)
      }
    </div>
  }
}

export default IconMagnet
