import React from 'react'
import DoublePageDTO from '../../DTO/DoublePageDTO'
// import Spinner from '../Spinner/Spinner'
import Page from '../Page/Page'
import './DoublePage.scss'
import {Trash, ArrowLeft, ArrowRight, FilePlus} from 'react-feather'

type DoublePageProps = {
  mainservice: any,
  addBookDoublePage: any,
  doublePage: DoublePageDTO
}

type DoublePageState = {
  loading: boolean,
  doublePage: DoublePageDTO,
  isAdmin: boolean,
  sortNumber: number
}

type ElementBlockNextPage = {id: number, block: boolean}

class DoublePage extends React.Component<DoublePageProps, DoublePageState> {
  mainService: any
  addBookDoublePage: any
  state: DoublePageState
  toolIconSize = '16px'
  nextPageEnabled: boolean = true
  elementBlockNextPage: ElementBlockNextPage[] = []
  constructor(props: DoublePageProps) {
    super(props)
    this.mainService = props.mainservice
    this.addBookDoublePage = props.addBookDoublePage

    this.state = {
      loading: true,
      isAdmin: this.mainService.isAdmin(),
      doublePage: props.doublePage,
      sortNumber: props.doublePage.sortNumber
    }
  }

  componentDidMount() {
  }
  componentDidUnount() {
  }
  getContent() {
    // TODO - please use nicer solution:
    window.location.reload()
  }
  addPage(position: number) {
    if (!this.state.doublePage) { return }
    this.setState({loading: true})
    this.state.doublePage.addPage(position).then(() => this.getContent())
  }
  renderPages() {
    const doublePage = this.state.doublePage
    if (!doublePage) { return }
    // case if only one doublepage available:
    const singlePageClass = (doublePage.pages.length === 1) ? 'w3-col m12 center' : 'w3-col m12 l6'
    const pagesOut = doublePage.pages.map((p, index: number) => {
      return <div className={singlePageClass} key={'page' + p.id}>
        <Page page={p} mainservice={this.mainService} selectFkt={(id: number) => console.log('selected id: ', id)}/>
        {this.state.isAdmin && <div className="addBlockRoot"><div className="addBlock" key={'AddPage' + index} onClick={() => this.addPage(index + 2)}>Page</div></div>}
      </div>
    })
    return <>
      {this.state.isAdmin && <div className="addBlockRoot"><div className="addBlock" key={'doublePage' + doublePage.id + 'AddPage-1'} onClick={() => this.addPage(1)}>Page</div></div>}
      {pagesOut}
    </>
  }
  renderToolBar() {
    return <div className="adminToolBar w3-container">
      <span className="w3-tag">DoublePage:</span>
      <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
      <button onClick={() => this.state.doublePage.setSortNumber(this.state.sortNumber).then(() => this.getContent())}>ok</button>
      <Trash className="tool" size={this.toolIconSize} onClick={() => this.state.doublePage.remove().then(() => this.getContent())} />
      <button className="tool" onClick={() => this.props.addBookDoublePage(this.state.doublePage.sortNumber - 1)}>
        <ArrowLeft size={this.toolIconSize} />
        <FilePlus size={this.toolIconSize} />
      </button>
      <button className="tool" onClick={() => this.props.addBookDoublePage(this.state.doublePage.sortNumber + 1)}>
        <FilePlus size={this.toolIconSize} />
        <ArrowRight size={this.toolIconSize} />
      </button>
      <button className="tool" onClick={() => this.state.doublePage.clone(this.mainService.getCurrentBookId()).then(() => this.getContent())}>
        <FilePlus size={this.toolIconSize} />
        <FilePlus size={this.toolIconSize} />
      </button>
      Style:
      <button onClick={() => this.state.doublePage.setLayout('light')}>Hell</button>
      <button onClick={() => this.state.doublePage.setLayout('dark')}>Dunkel</button>
      <button onClick={() => this.state.doublePage.setLayout('primary-color')}>Primär</button>
    </div>
  }
  render() {
    const className = (this.state.isAdmin) ? 'doublePageAdmin' : 'doublePage'
    const layoutClassName = this.state.doublePage.getLayout()
    const pages = this.renderPages()
    console.log('doublePage', this.state.doublePage)
    return <div className={`${className} w3-row`}>
      {this.state.isAdmin && this.renderToolBar()}
      {pages}
    </div>
  }
}

export default DoublePage
