import React from 'react'
import './Spinner.scss'

class Spinner extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props)
  }
  render() {
    return <>
      <div className='spinner-base'>
        <div className='w3-spin'>⚙</div>
      </div>
    </>
  }
}

export default Spinner
