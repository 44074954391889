export default class NavService {
  state: any
  knownItems = new Map([
    ['view', {default: 'base', type: 'string'}],
    ['book', {default: -1, type: 'number'}],
    ['page', {default: -1, type: 'number'}],
    ['session', {default: '', type: 'string'}],
  ])
  constructor() {
    this.resetState()
  }
  resetState() {
    this.state = new Map()
  }
  public setRoute(n: [string, string|number][]) {
    // preserve things???
    this.state = new Map(n)
    this.writeRoute()
  }
  private writeRoute() {
    let newHash = ''
    this.state.forEach((value: string | number, key: string) => {
      if (value) {
        newHash += `${key}=${value};`
      }
      return ''
    })
    window.location.hash = newHash
  }
  setPage(page: number) {
    this.state.set('page', parseInt(`${page}`, 10))
    this.writeRoute()
  }

  public getRoute(): any {
    const hashes = window.location.hash.replace('#', '').split(';')
    this.resetState()
    hashes.forEach((h: string) => {
      const kv = h.split('=')
      // Only save the keys we know:
      const knownItem = this.knownItems.get(kv[0])
      if (knownItem) {
        if (knownItem.type === 'number') {
          this.state.set(kv[0], parseInt(kv[1], 10))
        } else {
          this.state.set(kv[0], kv[1])
        }
      }
    })
    return this.state
  }
  public setVal(key: string, value: number|string) {
    this.state.set(key, value)
    this.writeRoute()
  }
  public getVal(key: string): number|string {
    const stateVal = this.state.get(key)
    if (stateVal) {
      return stateVal
    }
    const alt = this.knownItems.get(key)
    if (alt) {
      return alt.default
    }
    // never reached:
    return ''
  }
}
