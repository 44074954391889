import React from 'react'
import PageElementDTO from '../../../DTO/PageElementDTO'
import ElementPropDTO from '../../../DTO/ElementPropDTO'
import './MultipleChoice.scss'

type Props = {
  mainservice: any,
  pageElement: PageElementDTO
}

type State = {
  isAdmin: boolean,
  adminEdit: boolean,
  pageElement: PageElementDTO,
  editValue1Status: string,
  editValue2Status: string,
  propKey: string,
  emptyProp: ElementPropDTO,
}

class MultipleChoice extends React.Component<Props, State> {
  mainService: any
  state: State
  constructor(props: Props) {
    super(props)
    this.mainService = props.mainservice
    this.state = {
      isAdmin: this.mainService.isAdmin(),
      adminEdit: false,
      pageElement: props.pageElement,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
      propKey: '',
      emptyProp: new ElementPropDTO({id: -1, key1: '', key2: '', value1: '', value2: ''})
    }
  }
  componentDidMount() {
    console.log('MultipleChoice did mount ID', this.state.pageElement.id)
  }
  componentDidUnount() {
    console.log('MultipleChoice did unmount ID', this.state.pageElement.id)
  }

  getContent() {
    // TODO - please use nicer solution:
    window.location.reload()
  }

  renderMultipleChoiceItemEditValue(item: ElementPropDTO, displayName: string, name: 'key1'|'key2'|'value1'|'value2') {
    return <>
      <span className='w3-col m5'>{displayName}:</span>
      <input className='w3-col m6' value={item[name] as string} onChange={(event) => {
        item[name] = event.target.value
        this.setState({})
        //this.setState({emptyProp: item})
      }}/>
    </>
  }
  renderMultipleChoiceItemEdit(item: ElementPropDTO, index: number) {
    const propKey = `${this.state.pageElement.value1}-${this.state.pageElement.value2}-${this.state.pageElement.id}-${index}`
    const className = 'w3-col marker m1 w3-green'
    return <li key={propKey} className="w3-row">
      <div className={className}></div>
      {
        this.renderMultipleChoiceItemEditValue(item, 'Anzeigetext', 'value1')
      }
      {
        this.renderMultipleChoiceItemEditValue(item, 'Wert', 'value2')
      }
      <button onClick={() => {
        if (item.id > -1) {
          item.updateServer().then(() => this.setState({}))
        } else {
          this.state.pageElement.addElementProp(this.state.emptyProp).then(() => {
            this.setState({emptyProp: new ElementPropDTO({id: -1, key1: '', key2: '', value1: '', value2: ''})})
            this.getContent()
          })
        }
      }}>ok</button>
      {
        item.id > -1 &&
        <button onClick={() => {
          item.remove().then(() => this.getContent())
        }}>Entfernen</button>
      }
    </li>
  }


  render() {
    const elementProps = this.state.pageElement.elementProps
    const items = elementProps.filter((eP) => eP.key1 === 'multiplechoice')
    const surveyOptions = [
      { key: '-', name: 'Empty'},
      { key: 'survey1', name: 'Survey1'}
    ]
    let surveyPartOptions = [
      { key: '-', name: 'Empty'},
    ]
    for (let i = 1; i < 43; i++) {
      surveyPartOptions.push({ key: `surveypart${i}`, name: `SurveyPart${i}`})
    }
    if(this.state.isAdmin) {
      // Edit mode:


      const newKey = `${this.state.pageElement.value1}-${this.state.pageElement.value2}-${this.state.pageElement.id}-new`
      // Manage Items:
      return <>
        <select value={this.state.pageElement.value1 || ''} onChange={(event) => this.state.pageElement.setValue1(event.target.value).
          then(() => {
            this.setState({adminEdit: false})
          })}>
          {surveyOptions.map((k) => <option key={k.key} value={k.key}>{k.name}</option>)}
        </select>
        <select value={this.state.pageElement.value2 || ''} onChange={(event) => this.state.pageElement.setValue2(event.target.value).
          then(() => {
            this.setState({adminEdit: false})
          })}>
          {surveyPartOptions.map((k) => <option key={k.key} value={k.key}>{k.name}</option>)}
        </select>
        {
          items.map((i, index) => this.renderMultipleChoiceItemEdit(i, index))
        }
        {
          this.state.propKey !== newKey &&
          <li><button onClick={() => {
            this.setState({
              propKey: newKey,
              emptyProp: new ElementPropDTO({
                id: -1,
                key1: 'multiplechoice',
                key2: `${items.length}`,
                value1: '',
                value2: ''
              })
            })
          }}>Neuer Punkt</button></li>
        }
        {
          this.state.propKey === newKey &&
          this.renderMultipleChoiceItemEdit(this.state.emptyProp, -1)
        }
      </>
    }
    // Display mode:
    const pEV1 = this.state.pageElement.value1
    const pEV2 = this.state.pageElement.value2 || `id-${this.state.pageElement.id}`
    const seDa = this.mainService.getSessionData(pEV1, pEV2)
    const blockNextPage = !(seDa && seDa.value1)
    this.state.pageElement.elementBlockNextPage(blockNextPage)
    // Display Items:
    return items.map((i, index) => {
      let className = 'marker'
      if (seDa && seDa.value1 === `${index}`) {
        className += ' active'
      }
      //if (this.state.pageElement.value1)
      const propKey = `${this.state.pageElement.value1}-${this.state.pageElement.value2}-${this.state.pageElement.id}-${index}`
      return <li className={`bullet bullet-${index}`} key={propKey} onClick={() => {
        console.log('Point clicked')
        this.mainService.setSessionData(pEV1, pEV2, `${index}`, i.value2).then(() => {
          this.setState({})
          this.state.pageElement.elementBlockNextPage(false)
        })
      }}>
        <div className={className}></div>
        <div className='bullet-content'>
          {i.value1}
        </div>
      </li>
    })
  }
}

export default MultipleChoice
