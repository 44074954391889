import config from '../config.json'
import ElementPropDTO, {IElementProp} from './ElementPropDTO'

export type ElementType = {
  id: number,
  name: string,
  propKeyList: string[]
}

export default class PageElementDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public sortNumber: number
  public elementProps: ElementPropDTO[]
  public elementType: ElementType
  public value1: string
  public value2: string
  public parentItem: any

  constructor(o: IPageElement, parentItem?: any) {
    this.id = o.id
    if (parentItem) {
      this.parentItem = parentItem
    }
    this.sortNumber = o.sortNumber * 0.1
    this.value1 = this.parseStringFromServer(o.value1)
    this.value2 = this.parseStringFromServer(o.value2)
    this.elementProps = o.elementProps.map(
      (eP: IElementProp) => new ElementPropDTO(eP)
    )
    this.elementType = o.type
  }

  parseStringToServer(v: string) {
    return (v || '').replace(/\?/g, 'QUESTIONMARK').replace(/\%/g, 'PERCENTMARK')
  }

  parseStringFromServer(v: string) {
    return (v || '').replace('QUESTIONMARK', '?').replace('PERCENTMARK', '%')
  }

  elementBlockNextPage(block: boolean) {

    if (
      this.parentItem &&
      this.parentItem.parentItem &&
      this.parentItem.parentItem.blockNextPage
    ) {
      this.parentItem.parentItem.blockNextPage(this.id, block)
    }
  }

  public getDoublePageID(): number {
    if (!this.parentItem) { return -1 }
    return this.parentItem.getDoublePageID()
  }

  public async remove() {
    await fetch(`${this.apiPrefix}PageElement/${this.id}/remove`)
  }

  public async setSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setSortNumber/${(sortNumber * 10) - 1}`)
    // this.sortNumber = sortNumber - 0.1
    // TODO - talk to API
  }

  public async setValue1(v: string) {
    this.value1 = v
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setValue1/${this.parseStringToServer(v)}`)
  }

  public async setValue2(v: string) {
    this.value2 = v
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setValue2/${this.parseStringToServer(v)}`)
  }

  public async addElementProp(elementProp: ElementPropDTO) {
    const e = elementProp.get()
    await fetch(`${this.apiPrefix}PageElement/${this.id}/addProp/${e.key1}/${e.key2}/${this.parseStringToServer(e.value1)}/${this.parseStringToServer(e.value2)}`)
  }

}

export interface IPageElement {
  id: number
  sortNumber: number
  value1: string
  value2: string
  elementProps: IElementProp[]
  type: ElementType
}
