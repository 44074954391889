class Evaluation1TextGenerator {
  s1: number
  s2: number
  s3: number
  s4: number
  constructor(values: [number, number, number, number]) {
    this.s1 = values[0]
    this.s2 = values[1]
    this.s3 = values[2]
    this.s4 = values[3]
  }
  getText() {
    const s1 = this.s1
    const s2 = this.s2
    const s3 = this.s3
    const s4 = this.s4
    if (s1 > 13 && s3 === 0 && s4 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die auf Ihr Bedürfnis nach Klarheit, Genauigkeit und Kontrolle hinweisen. Sie sprechen an, wenn Vereinbarungen nicht eingehalten werden. im Ergebnis werden Sie vermutlich Erfolge haben, es kann aber sein, dass Sie sich selbst mehr Arbeit und Verantwortung aufhalsen als es unbedingt nötig ist.',
        'Es könnte in der Folge Mitarbeiterinnen und Mitarbeiter geben, die „ihre eigenen Wege“ gehen und mehr Verantwortung wollen, als Sie diese geben können. ',
        'Tendenziell scheint Ihre Arbeitsweise besonders geeignet zu sein, Menschen aus- oder heranzubilden oder mit ungelernten Kräften zu arbeiten. In diesem Umfeld werden Sie vermutlich gute Arbeitsergebnisse erzielen. ',
        'Weniger geeignet scheint Ihre Arbeitsweise zu sein, wenn es darum geht selbstdenkende Teams zu führen, in denen auch interdisziplinär starke Persönlichkeiten mitwirken. '
      ]
    }
    if (s2 > 13 && s3 === 0 && s4 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Sie geben in gewissem Maße Freiheiten, scheinen aber darauf bedacht zu sein, dass das abgelieferte Arbeitsergebnis Ihren Vorgaben und Vorstellungen entspricht. ',
        'Vereinbarungen scheinen Sie klar zu kommunizieren und nicht erfüllte Anforderungen scheinen Sie anzusprechen. ',
        'Delegation – und damit die Übergabe von komplexen Verantwortungen an Mitarbeiterinnen und Mitarbeiter Ihres Verantwortungsbereichs – scheint für Sie – aus welchen Gründen auch immer - schwierig zu sein.',
        'Es könnte in der Folge Mitarbeiterinnen und Mitarbeiter geben, die „ihre eigenen Wege“ gehen und mehr Verantwortung wollen, als Sie diese geben können. ',
        'Tendenziell scheint Ihre Arbeitsweise besonders geeignet zu sein, Menschen aus- oder heranzubilden. In diesem Umfeld werden Sie vermutlich gute Arbeitsergebnisse erzielen.'
      ]
    }
    if (s2 > 13 && s1 === 0 && s4 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Sie geben in gewissem Maße Freiheiten, scheinen aber darauf bedacht zu sein, dass das abgelieferte Arbeitsergebnis Ihren Vorgaben und Vorstellungen entspricht. ',
        'Vereinbarungen scheinen Sie klar zu kommunizieren, nicht erfüllte Anforderungen anzusprechen scheint Ihnen schwer zu fallen. Sie scheinen eher auf Einsicht und Verständnis zu setzen. ',
        'Wenn Menschen unter Ihrer Anleitung bewiesen haben, dass sie eine – auch komplexe – Aufgabe zu Ihrer Zufriedenheit erledigen können, dann besprechen Sie die Ziele, lassen den Menschen aber die Freiheit die Arbeit im Rahmen der Absprachen selbst zu erledigen Delegation – und damit die Übergabe von komplexen Verantwortungen an Mitarbeiterinnen und Mitarbeiter Ihres Verantwortungsbereichs – scheint für Sie – aus welchen Gründen auch immer - schwierig zu sein.  ',
        'Es könnte in der Folge Mitarbeiterinnen und Mitarbeiter geben, die „ihre eigenen Wege“ gehen und mehr Verantwortung wollen, als Sie diese geben können. ',
        'Tendenziell scheint Ihre Arbeitsweise besonders geeignet zu sein, Menschen aus- oder heranzubilden. In diesem Umfeld werden Sie vermutlich gute Arbeitsergebnisse erzielen. '
      ]
    }
    if (s3 > 13 && s1 === 0 && s2 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Ihre Devise scheint zu sein, dass Menschen Herausforderungen brauchen, damit sie an den Herausforderungen wachsen können.  ',
        'Sie scheinen einen guten Überblick über die fachlichen Kompetenzen Ihrer Mitarbeiterinnen und Mitarbeiter zu haben. Sie scheinen darauf zu vertrauen, dass die Mitarbeiterinnen und Mitarbeiter eine – auch komplexe – Aufgabe zu Ihrer Zufriedenheit erledigen können, wenn die Ziele besprochen sind. Daher lassen Sie den Menschen die Freiheit die Aufgabe im Rahmen der Absprachen selbst zu erledigen.  ',
        'Sie scheinen Ihre Mitarbeiterinnen und Mitarbeiter überwiegend mit Rat und Vertrauen zu unterstützen. Mit dieser Handlungsweise werden Sie vermutlich gute Erfolge erreichen. ',
        'Vereinbarungen scheinen Sie klar zu kommunizieren, nicht erfüllte Anforderungen anzusprechen scheint Ihnen schwer zu fallen und nicht ihrer kooperativen Art zu entsprechen. ',
        'Vor einer konsequenten Delegation – und damit die Übergabe von komplexen Verantwortungen an Mitarbeiterinnen und Mitarbeiter Ihres Verantwortungsbereichs – scheinen Sie noch zu scheuen. '
      ]
    }
    if (s3 > 13 && s4 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Ihre Devise scheint zu sein, dass Menschen Herausforderungen brauchen, damit sie an den Herausforderungen wachsen können.  ',
        'Sie scheinen einen guten Überblick über die fachlichen Kompetenzen Ihrer Mitarbeiterinnen und Mitarbeiter zu haben. Sie scheinen darauf zu vertrauen, dass die Mitarbeiterinnen und Mitarbeiter eine – auch komplexe – Aufgabe zu Ihrer Zufriedenheit erledigen können, wenn die Ziele besprochen sind. Daher lassen Sie den Menschen die Freiheit die Aufgabe im Rahmen der Absprachen selbst zu erledigen.  ',
        'Sie scheinen Ihre Mitarbeiterinnen und Mitarbeiter überwiegend mit Rat und Vertrauen zu unterstützen. Mit dieser Handlungsweise werden Sie vermutlich überwiegend gute Erfolge erreichen. ',
        'Vereinbarungen scheinen Sie klar zu kommunizieren, nicht erfüllte Anforderungen scheinen Sie klar anzusprechen und Ihnen ist offenbar wichtig auch in Konfliktsituationen Lösungen zu suchen. ',
        'Vor einer konsequenten Delegation – und damit die Übergabe von komplexen Verantwortungen an Mitarbeiterinnen und Mitarbeiter Ihres Verantwortungsbereichs – scheinen Sie noch zu scheuen. Es kann daher sein, dass Sie Mitarbeiterinnen und Mitarbeiter am Ende doch unterfordern. Das kann zur Folge haben, dass sich talentierte Menschen, die „noch ein Ziel haben“ umsehen, sich bei Ihnen für die erstklassige Führung bedanken und dann ein anderes Angebot annehmen. ',
        'Grundsätzlich sollte es ja das Ziel einer Führungskraft sein, dass die Mitarbeiterinnen und Mitarbeiter sich weiterentwickeln. Jedoch sollte die Initiative zur Weiterentwicklung eher von der Führungskraft ausgehen als vom Mitarbeiter. '
      ]
    }
    if (s4 > 13 && s1 === 0 && s2 === 0) {
      return [
        'Sie haben sich überwiegend dafür entschieden Ihren Mitarbeiterinnen und Mitarbeitern zu vertrauen und ihnen Freiheiten zu lassen. Ihre Devise scheint zu sein: Meine Mitarbeiterinnen und Mitarbeiter wissen was wie zu tun ist und machen das schon. Offenbar vertrauen Sie auf die Menschen und darauf, dass sie über einen natürlichen Leistungs- und Erfolgswillen verfügen. ',
        'Ihr Vertrauen in die Menschen scheint dazu zu führen, dass Sie mit den Mitarbeiterinnen und Mitarbeitern die Ziele komplexer Aufgaben verabreden und darauf vertrauen, dass diese inhaltlich und termingerecht zu Ihrer Zufriedenheit erledigt werden. Sie lassen Sie den Menschen alle Freiheit die Aufgabe im Rahmen der Absprachen selbst zu erledigen. ',
        'Wenn Abweichungen von den Vereinbarungen auftreten, dann setzten Sie offenbar darauf, dass die Mitarbeiterinnen und Mitarbeiter selbst die Lösung finden. Das direkte und konkrete Ansprechen von Problemen scheint nicht ihrer delegativen, vertrauenden Art zu entsprechen, auch dann nicht, wenn es dringend angebracht wäre. '
      ]
    }
    if (s2 + s3 > 16 && s1 === 0 && s4 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Ihre Devise scheint zu sein, dass Menschen Herausforderungen brauchen, damit sie an den Herausforderungen wachsen können.  ',
        'Sie scheinen darauf zu vertrauen, dass die Mitarbeiterinnen und Mitarbeiter eine – auch komplexe – Aufgabe zu Ihrer Zufriedenheit erledigen können, wenn die Ziele besprochen sind. Daher lassen Sie den Menschen die Freiheit die Aufgabe im Rahmen der Absprachen selbst zu erledigen.  ',
        'Sie scheinen Ihre Mitarbeiterinnen und Mitarbeiter überwiegend mit Rat und Vertrauen zu unterstützen. Mit dieser Handlungsweise werden Sie vermutlich gute Erfolge erreichen. ',
        'Vereinbarungen scheinen Sie klar zu kommunizieren, nicht erfüllte Anforderungen anzusprechen scheint Ihnen schwer zu fallen und nicht ihrer kooperativen Art zu entsprechen. ',
        'Vor einer konsequenten Delegation – und damit die Übergabe von komplexen Verantwortungen an Mitarbeiterinnen und Mitarbeiter Ihres Verantwortungsbereichs – scheinen Sie noch zu scheuen. '
      ]
    }
    if (s3 + s4 > 16 && s1 === 0 && s2 === 0) {
      return [
        'Sie haben sich überwiegend für Handlungsoptionen entschieden, die darauf ausgerichtet sind, dass sich Menschen im Rahmen ihrer Aufgaben weiterentwickeln können. Ihre Devise scheint zu sein: Meine Mitarbeiterinnen und Mitarbeiter wissen was wie zu tun ist und machen das schon. Sie scheinen darauf zu vertrauen, dass die Mitarbeiterinnen und Mitarbeiter eine – auch komplexe – Aufgaben erledigen können, wenn die Ziele besprochen sind.  ',
        'Sie scheinen Ihre Mitarbeiterinnen und Mitarbeiter überwiegend mit Rat und Vertrauen zu unterstützen. Mit dieser Handlungsweise werden Sie vermutlich überwiegend gute Erfolge erreichen. ',
        'Wenn Abweichungen von den Vereinbarungen auftreten, dann setzten Sie offenbar darauf, dass die Mitarbeiterinnen und Mitarbeiter selbst die Lösung finden. Das direkte und konkrete Ansprechen von Problemen scheint nicht ihrer delegativen, vertrauenden Art zu entsprechen, auch dann nicht, wenn es dringend angebracht wäre. '
      ]
    }
    if (s1 + s4 > 16 && s2 === 0 && s3 === 0) {
      return [
        'Ihr Handlungsspielraum scheint sehr breit und möglicherweise extrem zu sein. Einerseits scheinen Sie auf die Selbstverantwortung der Menschen zu setzen und sind bereit nahezu bedingungslos zu delegieren, andererseits scheinen Sie auf Abweichungen der mit Ihnen getroffenen Vereinbarungen schnell so zu reagieren, dass Sie das Heft selbst in die Hand nehmen, bis sich „die Dinge wieder in Ihrem Sinn“ verändert haben. ',
        'Das kann man so machen, und vermutlich zeigen Sie mit dieser Handlungsweise Charakter. Allerdings bietet sich den Menschen, die für Sie und mit Ihnen arbeiten kein klares Bild darüber, wie Sie im nächsten Augenblick handeln werden. Diese Unklarheit kann dann dazu führen, dass sich die Mitarbeiterinnen und Mitarbeiter nicht mehr trauen Verantwortung zu übernehmen oder aber abwandern. '
      ]
    }
    // Todo: Specifications not set by customer!
    if (s1 > 0 && s2 > 0 && s3 > 0 && s4 > 0) {
      return [
        'Sie verfügen über einen großen Handlungsspielraum und können scheinbar Ihr Führungsverhalten stark variieren. Es kann allerdings sein, dass Sie entweder Situation (dringlich und wichtig) und/oder die Person (kann sie das/will sie das) zuweilen anders einschätzen als es für ein erfolgreiches Handeln erforderlich wäre. ',
        'Konkret scheinen Sie in Situationen, in denen Anweisungen, Kontrolle und konsequentes Handeln erforderlich wäre, eher darauf zu setzen, dass Menschen in Eigenverantwortung Lösungen finden. ',
        'Die Analyse Ihrer Handlungsoptionen deutet zudem darauf hin, dass Sie zu sehr dazu neigen einzugreifen, wenn Sie Menschen Entwicklungschancen bieten könnten. ',
        'Um ein klareres Bild über Ihr Führungsverhalten im Bezug zu den in der von Ihnen bearbeiteten Unterlage zu erhalten, empfehlen wir Ihnen die Detailanalyse. '
      ]
    }
    // Todo: Specifications not set by customer!
    return [
      'Kompliment: Sie scheinen „Führung“ als eine Aufgabe verstanden zu haben, sowohl Klarheit und Konfliktbereitschaft aber auch Partnerschaft und Freiheit zugleich beinhaltet. ',
      'Im Wesentlichen schätzen Sie die in den von Ihnen bearbeiteten Führungssituationen sowohl in der Betrachtung von „dringlich/wichtig“ wie auch in der Einschätzung der jeweiligen Personen nach Kompetenz und emotionaler Reife (können/wollen) situationsgerecht ein. ',
      'Um ein klareres Bild über Ihr Führungsverhalten im Bezug zu den in der von Ihnen bearbeiteten Unterlage zu erhalten, empfehlen wir Ihnen die Detailanalyse. '
    ]
  }
}

export default Evaluation1TextGenerator
