import React from 'react'
import BookDTO from '../../../DTO/BookDTO'
import PageElementDTO from '../../../DTO/PageElementDTO'
import Evaluation1Flexible from './Evaluation1Flexible'
import Evaluation1Effective from './Evaluation1Effective'
import BarA from '../../BarA/BarA'
import CakeA from '../../CakeA/CakeA'

type Props = {
  mainservice: any,
  pageElement: PageElementDTO
}

type State = {
  isAdmin: boolean,
  sessionData: any[],
  book: BookDTO,
  pageElement: PageElementDTO,
  // pageElements: PageElementDTO[],
  // result: any,
  flexPoints: number,
  effectivePoints: number,
  styles: {name: string, key: string, amount: number}[],
  text: string[],
  gatherCounter: number
}

class Evaluation1 extends React.Component<Props, State> {
  mainService: any
  getDataInterval: any
  doublePageId: number
  justEntered: boolean = true
  constructor(props: Props) {
    super(props)
    this.mainService = props.mainservice
    this.state = {
      isAdmin: this.mainService.isAdmin(),
      sessionData: [],
      book: this.mainService.readBook(),
      flexPoints: 0,
      effectivePoints: 0,
      pageElement: props.pageElement,
      styles: [
        {name: 'Anweisend', key: 'A', amount: 0},
        {name: 'Trainierend', key: 'B', amount: 0},
        {name: 'Coachend', key: 'C', amount: 0},
        {name: 'Delegierend', key: 'D', amount: 0},
      ],
      text: [],
      gatherCounter: 0
    }
    this.doublePageId = this.state.pageElement.getDoublePageID()
  }

  componentDidMount() {
    this.getDataInterval = setInterval(() => {
      if (this.mainService.isCurrentDoublePage(this.doublePageId)) {
        if (this.justEntered) {
          this.justEntered = false
          this.gatherData()
        }
      } else {
        this.justEntered = true
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.getDataInterval)
    this.justEntered = true
  }

  gatherData() {
    console.log('gather eva data')
    let flexible = new Evaluation1Flexible(this.mainService.getSessionData() as {key2: string, value2: string}[])
    let effective = new Evaluation1Effective()
    this.setState({
      sessionData: this.mainService.getSessionData(),
      flexPoints: flexible.flexiblePoints,
      effectivePoints: effective.calculate(this.mainService.getSessionData() as {key2: string, value2: string}[]),
      styles: [
        {name: 'Anweisend', key: 'A', amount: flexible.pointsByCategory[0]},
        {name: 'Trainierend', key: 'B', amount: flexible.pointsByCategory[1]},
        {name: 'Coachend', key: 'C', amount: flexible.pointsByCategory[2]},
        {name: 'Delegierend', key: 'D', amount: flexible.pointsByCategory[3]},
      ],
      text: flexible.text,
      gatherCounter: this.state.gatherCounter + 1
    })
  }

  renderBarBox(headline: string, value: number, counter: number) {
    return <div className='w3-col s12 m6 l6'>
      <h2>{headline}</h2>
      <BarA value={value} counter={counter}/>
    </div>
  }
  render() {
    console.log('render eva', this.state.flexPoints)
    return <>
      <div className={`w3-col counter-${this.state.gatherCounter}`}>
      <h2>Anteilsverteilung der von Ihnen bevorzugten Führungsstile</h2>
      <CakeA data={this.state.styles}/>
      {
        this.renderBarBox('Deine Stil-Flexibilität', this.state.flexPoints, this.state.gatherCounter)
      }
      {
        this.renderBarBox('Deine Stil-Effektivität', this.state.effectivePoints, this.state.gatherCounter)
      }
      </div>
      <h2>Kurzanalyse</h2>
      {
        this.state.text.map((p, index) => { return <p key={index}>{p}</p> })
      }
    </>
  }
}

export default Evaluation1
