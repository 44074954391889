import Evaluation1TextGenerator from './Evaluation1TextGenerator'
const Evaluation1FlexibleMatrix = [
  ['1', 'A', 'C', 'D', 'B'],
  ['2', 'A', 'C', 'B', 'D'],
  ['3', 'C', 'B', 'A', 'D'],
  ['4', 'C', 'B', 'D', 'A'],
  ['5', 'A', 'B', 'C', 'D'],
  ['6', 'D', 'A', 'C', 'B'],
  ['7', 'A', 'C', 'D', 'B'],
  ['8', 'B', 'D', 'A', 'C'],
  ['9', 'B', 'D', 'A', 'C'],
  ['10', 'D', 'B', 'A', 'C'],
  ['11', 'B', 'C', 'D', 'A'],
  ['12', 'B', 'C', 'D', 'A'],
  ['13', 'A', 'C', 'B', 'D'],
  ['14', 'B', 'C', 'D', 'A'],
  ['15', 'D', 'A', 'C', 'B'],
  ['16', 'D', 'B', 'A', 'C'],
  ['17', 'A', 'C', 'B', 'D'],
  ['18', 'B', 'D', 'A', 'C'],
  ['19', 'B', 'D', 'C', 'A'],
  ['20', 'A', 'B', 'C', 'D'],
]
class Evaluation1Flexible {
  lookUpMatrix = new Map()
  maxPoints = 30

  public flexiblePoints: number = 0
  public pointsByCategory: [number, number, number, number] = [0,0,0,0]
  public text: string[] = []
  constructor(data: {key2: string, value2: string}[]) {
    Evaluation1FlexibleMatrix.forEach((i) => {
      this.lookUpMatrix.set(`surveypart${i[0]}`, [i[1], i[2], i[3], i[4]])
    })
    this.calculate(data)
  }

  calculate(data: {key2: string, value2: string}[]) {
    let pointHolder: [number, number, number, number] = [0,0,0,0]
    data.forEach((item) => {
      const key = item.key2
      const v = item.value2
      const entry = this.lookUpMatrix.get(key)
      if (!entry) { return }
      const pointFor = entry.indexOf(v)
      pointHolder[pointFor] += 1
    })
    this.pointsByCategory = pointHolder
    // All Points have been collected - now we have to calculate:
    const reducedPoints = pointHolder.map((pH) => Math.abs(pH - 5))
    const sum = reducedPoints.reduce((a, b) => a + b)
    const result = 30 - sum
    this.flexiblePoints = Math.round(result / this.maxPoints * 100)
    let textGenerator = new Evaluation1TextGenerator(pointHolder)
    this.text = textGenerator.getText()
  }
}

export default Evaluation1Flexible
