import config from '../config.json'
import PageElementDTO, {IPageElement} from './PageElementDTO'

export default class PageDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public sortNumber: number
  public pageElements: PageElementDTO[]
  public parentItem: any
  constructor(o: IPage, parentItem?: any) {
    this.id = o.id
    if (parentItem) {
      this.parentItem = parentItem
    }
    this.sortNumber = o.sortNumber * 0.1
    this.pageElements = o.pageElements.sort((a, b) => {
      return a.sortNumber < b.sortNumber ? -1 : 1
    }).map((p) => new PageElementDTO(p, this))
  }

  public getDoublePageID(): number {
    if (!this.parentItem) { return -1 }
    return this.parentItem.id
  }

  public async setSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    await fetch(`${this.apiPrefix}Page/${this.id}/setSortNumber/${(sortNumber * 10) - 1}`)
    // this.sortNumber = sortNumber - 0.1
    // TODO - talk to API
  }

  public async remove() {
    await fetch(`${this.apiPrefix}Page/${this.id}/remove`)
  }

  public async newSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    this.sortNumber = sortNumber
    // TODO - talk to API
  }

  public async addPageElement(elementTypeId: number, position: number) {
    await fetch(`${this.apiPrefix}page/${this.id}/addPageElement/elementType/${elementTypeId}/sortNumber/${position * 10 - 1}`)
    // TODO: Add new Double Page??
  }

  public getAllPageElements(): PageElementDTO[] {
    return this.pageElements
  }

}

export interface IPage {
  id: number
  sortNumber: number
  pageElements: IPageElement[]
}
