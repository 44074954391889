import React from 'react'
import PageElementDTO, {IPageElement} from '../../../DTO/PageElementDTO'

type Props = {
  mainservice: any,
  pageElement: PageElementDTO
}

type State = {
  isAdmin: boolean,
  adminEdit: boolean,
  pageElement: PageElementDTO,
  editValue1Status: string,
  editValue2Status: string,
}

class Text extends React.Component<Props, State> {
  mainService: any
  state: State
  constructor(props: Props) {
    super(props)
    this.mainService = props.mainservice
    this.state = {
      isAdmin: this.mainService.isAdmin(),
      adminEdit: false,
      pageElement: props.pageElement,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
    }
  }

  render() {
    if (this.state.isAdmin) {
      return <>
        <textarea autoFocus value={this.state.editValue1Status || ''} onChange={(event) => this.setState({editValue1Status: event.target.value})}/>
        <button className="w3-btn w3-green"
          onClick={
            () => this.state.pageElement.setValue1(this.state.editValue1Status).
              then(() => {
                this.setState({adminEdit: false})
              })
          }
        >
          ok
        </button>
        <button className="w3-btn"
          onClick={() => this.setState({adminEdit: false})
          }
        >
          Abbrechen
        </button>
      </>
    }
    const displayContent = this.state.pageElement.value1 || ''
    switch (this.state.pageElement.value2) {
      case 'h1':
        return <h1>{displayContent}</h1>
      case 'h2':
        return <h2>{displayContent}</h2>
      case 'bold-paragraph':
        return <p><b>{displayContent}</b></p>
      case 'poster':
        return <div className="poster">
          {displayContent}
        </div>
      default:
        return <p>{displayContent}</p>
    }
  }
}

export default Text
